import StateMachine from '@/class/stateMachine'
import { Component, Vue } from 'vue-property-decorator'

// apis
import { getCustomConfuseData } from '@/api/dashboard'

// tools
import { fixedRound, getVal } from '@/utils/index'
import { refreshToken } from '@/utils/tokenExpire'
import { fillData } from '../utils/index'
import dayjs from 'dayjs'

@Component({
  name: 'CustomerConfuseMixin'
})
export default class extends Vue {
  customerConfuseLastUpdate: string = '--'; // 客户抱怨失效件最新更新时间
  customerConfuseUpdateCycle: string = '--'
  customerConfuseTableData: Array<object> = []; // 客户抱怨失效件 tableData

  customerConfuseMaxValue: number = 0 // 客户抱怨失效件 tableData 最大值

  customerConfuseStateMachine: StateMachine = new StateMachine();

  customerConfuseModel: number = 3;
  customerConfuseOpts: Array<object> = [
    // 客户抱怨失效件 opts
    { label: '近1月', value: 1 },
    { label: '近3月', value: 3 },
    { label: '近6月', value: 6 },
    { label: '近12月', value: 12 }
  ];

  get getWarrantyVal() {
    return (data, count) => {
      const value = data && data.reduce((acc, curr) => {
        if (curr.failureType.toUpperCase() === 'WARRANTY') {
          return acc + curr.counts
        } else return acc + 0
      }, 0)
      return {
        value: value,
        percentage: fixedRound(value / count * 100)
      }
    }
  }

  get getPolce() {
    return (data, count) => {
      const value = data && data.reduce((acc, curr) => {
        if (curr.failureType.toUpperCase() === 'POLICY') {
          return acc + curr.counts
        } else return acc + 0
      }, 0)
      return {
        value: value,
        percentage: fixedRound(value / count * 100)
      }
    }
  }

  get getCampaignTRP() {
    return (data, count) => {
      const value = data && data.reduce((acc, curr) => {
        if (curr.failureType.toUpperCase() === 'CAMPAIGN/TRP') {
          return acc + curr.counts
        } else return acc + 0
      }, 0)
      return {
        value: value,
        percentage: fixedRound(value / count * 100)
      }
    }
  }

  get getFieldTest() {
    return (data, count) => {
      const value = data && data.reduce((acc, curr) => {
        if (curr.failureType.toUpperCase() === 'FIELD TEST') {
          return acc + curr.counts
        } else return acc + 0
      }, 0)
      return {
        value: value,
        percentage: fixedRound(value / count * 100)
      }
    }
  }

  get getExtCov() {
    return (data, count) => {
      const value = data && data.reduce((acc, curr) => {
        if (curr.failureType.toUpperCase() === 'EXTCOV') {
          return acc + curr.counts
        } else return acc + 0
      }, 0)
      return {
        value: value,
        percentage: fixedRound(value / count * 100)
      }
    }
  }

  get convertThousandCount() {
    return (data) => {
      // @ts-ignore
      return Math.abs(data) > 999 ? Math.sign(data) * ((Math.abs(data) / 1000).toFixed(1)) + 'k' : Math.sign(data) * Math.abs(data)
    }
  }

  async getCustomConfuseData(selectors) {
    this.customerConfuseStateMachine.changeState('reload')
    try {
      const { data } = await getCustomConfuseData({
        headers: {
          oem: encodeURIComponent(selectors.oem.join(',')),
          platform: encodeURIComponent(selectors.platform.join(',')),
          userGroup: encodeURIComponent(selectors.userGroup.join(','))
        },
        params: { date: this.calcDate(this.customerConfuseModel) }
      })
      // const data = { lastTime: '暂无', data: [{ data: [{ counts: 2, failureType: 'POLCE', failurePartsName: '车轮1' }, { counts: 1, failureType: 'WARRANTY', failurePartsName: '车轮1' }], name: '车轮1', sum: 3 }, { data: [{ counts: 1, failureType: 'Campaign_TRP', failurePartsName: '车轮3' }], name: '车轮3', sum: 1 }, { data: [{ counts: 1, failureType: 'POLCE', failurePartsName: '方向盘3' }], name: '方向盘3', sum: 1 }, { data: [{ counts: 1, failureType: 'FIELD_TEST', failurePartsName: '方向盘2' }], name: '方向盘2', sum: 1 }, { data: [{ counts: 1, failureType: 'Campaign_TRP', failurePartsName: '方向盘' }], name: '方向盘', sum: 1 }], pl: '暂无' }
      this.customerConfuseTableData = data.data
      if (data.data.length) fillData(this.customerConfuseTableData)
      this.customerConfuseMaxValue = getVal(() => data.data[0].sum, 0)
      this.customerConfuseLastUpdate = data.lastTime
      this.customerConfuseUpdateCycle = data.pl
      this.customerConfuseStateMachine.changeState('resolve')
    } catch (error) {
      if (error.message === '401:expired') {
        await refreshToken()
        location.reload()
      }

      if (error.message !== '402:abort') {
        console.error('获取 customConfuse 数据失败！')
        this.customerConfuseStateMachine.changeState('reject')
      }
    }
  }

  /**
   * 时间跨度计算
   * @param dateRangeId 间隔 id，由上部 el-select 选择决定
   */

  calcDate(dateRangeId) {
    return dayjs().subtract(dateRangeId, 'month').format('YYYY-MM-DD')
  }

  customerConfuseTableRowClass({ row }) {
    const classList: Array<string> = []
    if (row.hidden) classList.push('hide')
    if (!row.color) classList.push('without-color')

    return classList.join(' ')
  }

  customerConfuseTableRowStyle({ row, rowIndex }) {
    return {
      'background-color': row.color
    }
  }
}
