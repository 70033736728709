import request from '@/utils/request'

// oem 选择器 opts
export const getOemOps = () => {
  return request({
    url: '/business/oem/listAll',
    method: 'get'
  })
}

// 客户群体选择器 opts
export const getUserGroupOps = () => {
  return request({
    url: '/business/userGroup/listAll',
    method: 'get'
  })
}

// 客户群体选择器 opts
export const getPlatformOps = () => {
  return request({
    url: '/business/platform/listAll',
    method: 'get'
  })
}

// 获取 dutycycle
export const getDutyCycleData = params => {
  return request({
    url: '/business/dutyCycle/initDutyCycle',
    method: 'get',
    headers: { ...params.headers },
    params: params.params
  })
}

// ccMax
export const getCCMaxTempData = params => {
  return request({
    url: '/business/ccTemperature/intiCcTemperatureInfo',
    method: 'get',
    headers: { ...params.headers },
    params: params.params
  })
}

// turbo
export const getTurboData = params => {
  return request({
    url: '/business/ccTemperature/initTurboInfo',
    method: 'get',
    headers: { ...params.headers },
    params: params.params
  })
}

// 故障限扭时长
export const getCrashData = params => {
  return request({
    url: '/business/faultCode/faultCodeLimit',
    method: 'get',
    headers: { ...params.headers },
    params: params.params
  })
}

// 地图数据
export const getMapData = params => {
  return request({
    url: '/business/map/initMapInfo',
    method: 'get',
    headers: { ...params.headers },
    params: params.params
  })
}

// 地图右上角数据
export const getMapProfileData = params => {
  return request({
    url: '/business/map/initMapRightInfo',
    method: 'get',
    headers: { ...params.headers },
    params: params.params
  })
}

// 客户抱怨失效件
export const getCustomConfuseData = params => {
  return request({
    url: '/business/failureParts/initFailureParts',
    method: 'get',
    headers: { ...params.headers },
    params: params.params
  })
}
