// import StateMachine from '@/class/stateMachine'
import { Component, Vue } from 'vue-property-decorator'

// apis
import { getMapProfileData } from '@/api/dashboard'

// tools
import { refreshToken } from '@/utils/tokenExpire'

@Component({
  name: 'MapProfileMixin'
})
export default class extends Vue {
  // mapProfileStateMachine: StateMachine = new StateMachine();

  mapProfileData: Array<object> = [];

  async getMapProfileData(selectors) {
    try {
      const { data } = await getMapProfileData({
        headers: {
          oem: encodeURIComponent(selectors.oem.join(',')),
          platform: encodeURIComponent(selectors.platform.join(',')),
          userGroup: encodeURIComponent(selectors.userGroup.join(','))
        }
      })
      this.mapProfileData = Object.freeze(data)
    } catch (error) {
      if (error.message === '401:expired') {
        await refreshToken()
        location.reload()
      }
      console.error('获取 mapProfileData 数据失败！')
    }
  }
}
