import qs from 'qs'
import axios from 'axios'

export function generateReqKey(config) {
  const { method, url, params, data } = config
  // return [method, url, qs.stringify(params), qs.stringify(data)].join('&')
  return [method, url].join('&')
}

const pendingRequest = new Map()
export function addPendingRequest(config) {
  const requestKey = generateReqKey(config)
  config.cancelToken = config.cancelToken || new axios.CancelToken((cancel) => {
    if (!pendingRequest.has(requestKey)) {
      pendingRequest.set(requestKey, cancel)
    }
  })
}

export function removePendingRequest(config) {
  const requestKey = generateReqKey(config)
  if (pendingRequest.has(requestKey)) {
    const cancelToken = pendingRequest.get(requestKey)
    cancelToken(requestKey)
    pendingRequest.delete(requestKey)
  }
}
