








// decorator
import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'

// mixins
import ResizeMixin from '../mixins/resize'
import AmapRenderMixin from '../ccg/mixins/AmapRenderMixin'

import 'echarts-extension-amap'

@Component({
  name: 'AmapChart'
})
export default class extends mixins(AmapRenderMixin, ResizeMixin) {
  created() {
    this.BASE_CONFIG = {
      title: {
        text: '',
        subtext: '',
        sublink: '',
        left: 'center',
        textStyle: {
          color: '#fff'
        }
      },
      tooltip: {
        trigger: 'item'
      },
      amap: {
        viewMode: '3D',
        center: [
          110,
          39
        ],
        zoom: 4.2,
        resizeEnable: false,
        renderOnMoving: false,
        mapStyle: 'amap://styles/a9fe86cb7a5ac882653025d58d988bee'
      }
    }
  }

  mounted() {
    this.drawChart(this.BASE_CONFIG)
  }
}
