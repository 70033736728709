import { Component, Vue } from 'vue-property-decorator'

// tools
import StateMachine from '@/class/stateMachine'
import { refreshToken } from '@/utils/tokenExpire'
import { debounce } from 'lodash'

// apis
import { getMapData } from '@/api/dashboard'

let mapData = []
// @ts-ignore
// eslint-disable-next-line no-new
const geocoder = new AMap.Geocoder()

const toFaultHTML = (platform, esn, addressText, faultCodeText) => {
  const faultCodeList: Array<string> = []

  faultCodeText.forEach((faultCodeItem, itemInd) => {
    faultCodeList.push(
      `<ul class="fault-code-list-window-wrapper">
        <li class="list-item"><span class="item-title">故障码:</span><span class="item-value">${faultCodeItem.FAULT_CODE}</span></li>
        <li class="list-item"><span class="item-title">发生时间:</span><span class="item-value">${faultCodeItem.OCCURRENCE_DATE_TIME}</span></li>
        <li class="list-item"><span class="item-title">发生地址:</span><span class="item-value">${faultCodeItem.text || '--'}</span></li>
      </ul>`
    )
  })

  return `<ul class="mark-info-window-wrapper">
      <li class="info-window-item"><span class="item-title">ESN:</span><span class="item-value">${platform}</span></li>
      <li class="info-window-item"><span class="item-title">平台:</span><span class="item-value">${esn}</span></li>
      <li class="info-window-item"><span class="item-title">位置:</span><span class="item-value">${addressText}</span></li>
    </ul>
    ${faultCodeList.join(' ')}`
}

const toHTML = (platform, esn, addressText) => {
  return `<ul class="mark-info-window-wrapper">
      <li class="info-window-item"><span class="item-title">ESN:</span><span class="item-value">${platform}</span></li>
      <li class="info-window-item"><span class="item-title">平台:</span><span class="item-value">${esn}</span></li>
      <li class="info-window-item"><span class="item-title">位置:</span><span class="item-value">${addressText}</span></li>
    </ul>`
}
@Component({
  name: 'MapChartMixin'
})
export default class extends Vue {
    mapChartMachine: StateMachine = new StateMachine();
    mapDataLen: number = 0 // 车辆连接量
    mapDataLoaded: boolean = false // 地图数据是否加载完成
    mapLastUpdate: string = '--' // 地图数据上次更新时间
    mapUpdateCycle: string = '--' // 地图数据更新频率

    async getMapData(selectors) {
      this.mapChartMachine.changeState('reload')
      try {
        const { data } = await getMapData({
          headers: {
            oem: encodeURIComponent(selectors.oem.join(',')),
            platform: encodeURIComponent(selectors.platform.join(',')),
            userGroup: encodeURIComponent(selectors.userGroup.join(','))
          }
        })
        // const data = { data: [], lastTime: '--', pl: '--' }
        this.mapChartMachine.changeState('resolve')
        mapData = data.data
        this.mapDataLoaded = true
        this.mapDataLen = data.data.length
        this.mapLastUpdate = data.lastTime
        this.mapUpdateCycle = data.pl
      } catch (error) {
        if (error instanceof Error) {
          if (error.message === '401:expired') {
            await refreshToken()
            location.reload()
          }

          if (error.message !== '402:abort') {
            console.error('获取 map 数据失败！')
            this.mapChartMachine.changeState('reject')
          }
        }
      }

      interface mapOrgDataItem {
        lnglat: Array<string>,
        DISPLACEMENT: string,
        ESN: string,
        VEHICLE_MODEL: string,
        status: string,
        faultCode?: Array<{
          FAULT_CODE: string,
          LATITUDE: string,
          LONGITUDE: string,
          OCCURRENCE_DATE_TIME: string,
          faultDescription: string
        }>
      }

      type mapRenderDataItem = Array<any>

      try {
        const normalData: Array<mapRenderDataItem> = [] // 正常数据
        const wrongData: Array<mapRenderDataItem> = [] // 问题数据

        mapData.forEach((data: mapOrgDataItem) => {
          const _data: mapRenderDataItem = [+data.lnglat[0], +data.lnglat[1], data.DISPLACEMENT, data.ESN]
          if (data.status === '1') {
            normalData.push(_data)
          } else {
            _data.push(data.faultCode)
            // console.log(data.faultCode)
            wrongData.push(_data)
          }
        })

        const baseSeriesData = {
          type: 'scatter',
          coordinateSystem: 'amap',
          symbolSize: 5,
          progressive: 800
        }

        // @ts-ignore
        this.$refs.mapChartRef.drawChart({
          series: [
            {
              data: normalData,
              itemStyle: { color: '#30DD07' },
              ...baseSeriesData,
              tooltip: {
                confine: true,
                formatter: debounce((params, ticket, callback) => {
                  const esn = params.data[2]
                  const platform = params.data[3]
                  let addressText = '--' // 地理描述文字

                  const lnglat = [+params.data[0], +params.data[1]]

                  geocoder.getAddress(lnglat, function(status, result) {
                    if (status === 'complete' && result.info === 'OK') {
                      addressText = result.regeocode.formattedAddress
                    } else if (status === 'no_data') {
                      addressText = '未查询到数据'
                    }

                    callback(ticket, toHTML(platform, esn, addressText))
                  })
                  return toHTML('数据加载中...', '数据加载中...', '数据加载中...')
                }, 250)
              }
            },
            {
              data: wrongData,
              itemStyle: { color: '#d9001b' },
              name: 'wrongData',
              ...baseSeriesData,
              tooltip: {
                confine: true,
                formatter: debounce((params, ticket, callback) => {
                  const esn = params.data[2]
                  const platform = params.data[3]
                  const faultCode = params.data[4]
                  let addressText = '--' // 地理描述文字
                  const faultCodeText: Array<any> = [] // 故障码描述文件

                  const lnglat = [+params.data[0], +params.data[1]]
                  geocoder.getAddress(lnglat, function(status, result) {
                    if (status === 'complete' && result.info === 'OK') {
                      addressText = result.regeocode.formattedAddress
                    } else if (status === 'no_data') {
                      addressText = '未查询到数据'
                    }

                    callback(ticket, toFaultHTML(platform, esn, addressText, faultCode))
                  })

                  faultCode.forEach((item, itemIndex) => {
                    const lnglat = [+item.LONGITUDE, +item.LATITUDE]
                    faultCodeText[itemIndex] = {
                      FAULT_CODE: item.FAULT_CODE,
                      OCCURRENCE_DATE_TIME: item.OCCURRENCE_DATE_TIME,
                      text: '数据加载中...'
                    }

                    geocoder.getAddress(lnglat, function(status, result) {
                      if (status === 'complete' && result.info === 'OK') {
                        faultCodeText[itemIndex].text = result.regeocode.formattedAddress
                      } else if (status === 'no_data') {
                        faultCodeText[itemIndex].text = '未查询到数据'
                      }

                      callback(ticket, toFaultHTML(platform, esn, addressText, faultCodeText))
                    })
                  })

                  return toFaultHTML('数据加载中...', '数据加载中...', addressText, faultCodeText)
                }, 250)
              }
            }
          ]
        })
      } catch (e) {
        this.$message.error('地图点绘制失败！')
        console.error('地图点绘制失败', e)
      }
    }
}
