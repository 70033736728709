export default class StateMachine {
    currState: string = 'init'
    stateMap: object = {
      init: {
        loading: 'loading',
        reload: 'loading'
      },
      loading: {
        resolve: 'resolved',
        reject: 'rejected'
      },
      resolved: {
        reload: 'loading'
      },
      rejected: {
        reload: 'loading'
      }
    }

    changeState(name) {
      const state = this.currState

      if (this.stateMap[state][name]) {
        this.currState = this.stateMap[state][name]
      } else {
        console.error(`状态不能由 ${state} 转换为 ${name}`)
      }
    }
}
