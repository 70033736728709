import echarts, { ECharts, EChartOption } from 'echarts'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

// tools
import deepmerge from 'deepmerge'

@Component({
  name: 'ChartRenderMixin'
})
export default class extends Vue {
  chart!: ECharts
  BASE_CONFIG: EChartOption = {}

  @Prop({ default: '200px' }) readonly chartHeight!: string
  @Prop({ default: '100%' }) readonly chartWidth!: string
  @Prop({ default: () => {} }) readonly addOptions!: EChartOption // 外部引用所定义的个性化配置

  // 基础配置和自定义配置合并而成的总数居
  get resolvedOptions(): EChartOption {
    if (!this.addOptions) return deepmerge(this.BASE_CONFIG, {})
    return deepmerge(this.BASE_CONFIG, this.addOptions)
  }

  @Watch('resolvedOptions', { deep: true })
  onOptionsChange(lastOptions: EChartOption): void {
    this.drawChart(lastOptions)
  }

  // mounted() {
  //   this.drawChart(this.resolvedOptions)
  // }

  beforeDestroy() {
    if (!this.chart) return
    this.chart.dispose()
  }

  /**
   * 绘制图表
   */

  drawChart(options: EChartOption) {
    if (!this.chart) {
      this.chart = echarts.init(this.$el as HTMLDivElement)
    }
    this.chart.setOption(options, true)
  }
}
