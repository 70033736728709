import { UserModule } from '@/store/modules/user'
import router from '@/router'

/**
 * 检测 token 是否过期并刷新 token
 */

export function checkExpire() {
  const tokenValidTime = +UserModule.tokenValidTime * 60 * 1000
  const tokenLastUpdateTime = UserModule.tokenLastUpdateTime
  const nowTime = +new Date()

  const tokenHostDur = nowTime - tokenLastUpdateTime

  const isExpired = tokenHostDur >= tokenValidTime
  // const isExpired = true
  // console.log('token 是否过期', isExpired, tokenHostDur)

  return isExpired
}

export async function refreshToken() {
  try {
    await UserModule.RefreshToken({
      username: UserModule.username,
      password: UserModule.pwd
    })
  } catch {
    router.replace({
      name: 'Login'
    })
    return Promise.reject(new Error('token 刷新失败！'))
  }
}
