const oneMin = 60 * 1000

// 前端刷新各个图表的定时器
export const CHART_UPDATE_CYCLE_FE = {
  dutyCycle: 40 * oneMin,
  ccMax: 43 * oneMin,
  turbo: 46 * oneMin,
  crash: 49 * oneMin,
  customConfuse: 52 * oneMin,
  map: 60 * oneMin,
  mapProfile: 55 * oneMin
}
