














































































































































































































































































































































































































































































































































































































































































import fullscreen from 'vue-fullscreen'
import Vue from 'vue'

// decorator
import Component, { mixins } from 'vue-class-component'

// tools
import { parseTime } from '@/utils/index'
import { debounce } from 'lodash'

// components
import ResponseContainer from '@/components/ResponseContainer/ResponseContainer.vue'
import SingleBarChart from '@/components/Charts/ccg/SingleBarChart.vue'
import DoubleBarChart from '@/components/Charts/ccg/DoubleBarChart.vue'
import BubbleChart from '@/components/Charts/ccg/BubbleChart.vue'
import AmapChart from '@/components/Charts/ccg/AmapChart.vue'
import { VueListMarquee } from 'vue-list-marquee'

// mixins
import CCMaxTempMixin from './mixins/CCMaxTemp'
import TurboEntryPressMixin from './mixins/TurboEntryPress'
// import MileMixin from './mixins/Mile'
import CustomerConfuseMixin from './mixins/CustomerConfuse'
import CrashCaseTimeMixin from './mixins/CrashCauseTime'
import MapProfileMixin from './mixins/MapProfile'
import DutyCycleMixin from './mixins/DutyCycle'
import MapChartMixin from './mixins/MapChart'

// store
import { UserModule } from '@/store/modules/user'

// api
import { getOemOps, getUserGroupOps, getPlatformOps } from '@/api/dashboard'

// config
import { CHART_UPDATE_CYCLE_FE } from './config'

Vue.use(fullscreen)

@Component({
  name: 'Dashboard',
  components: {
    ResponseContainer,
    SingleBarChart,
    DoubleBarChart,
    BubbleChart,
    AmapChart,
    VueListMarquee
  }
})
export default class extends mixins(MapChartMixin, DutyCycleMixin, CCMaxTempMixin, TurboEntryPressMixin, CustomerConfuseMixin, CrashCaseTimeMixin, MapProfileMixin) {
  currentTime: string | null = ''

  logoutLoading: boolean = false // 退出登录 loading
  isFullScreen: boolean = false // 是否全屏
  get fullScreenTitle() {
    return this.isFullScreen ? '退出全屏' : '全屏'
  }

  oemSeleModel: Array<string> = [] // OEM筛选
  oemOpts: Array<object> = []
  platformSeleModel: Array<string> = [] // 平台筛选
  platformOpts: Array<object> = []
  clientGroupModel: Array<string> = [] // 客户群体
  clientGroupOpts: Array<object> = []
  lastFilter: string = `${this.oemSeleModel.join(',')}${this.platformSeleModel.join(',')}${this.clientGroupModel.join(',')}` // 上一次的搜索条件

  renderChart: boolean = false // 渲染图表的总开关。因为部分 chart 使用组件的方式开发，加载的先后顺序和 element 部分组件冲突，所以使用一个开关控制其先后顺序

  get debTopSelectChange() {
    return debounce(() => {
      const nowFilter = `${this.oemSeleModel.join(',')}${this.platformSeleModel.join(',')}${this.clientGroupModel.join(',')}`
      if (nowFilter !== this.lastFilter) {
        this.lastFilter = nowFilter
        this.topSelectChange()
      }
      console.log({ nowFilter, lastFilter: this.lastFilter })
    }, 200)
  }

  async mounted() {
    // 顶部时间
    setInterval(() => {
      const nowTime = +new Date()
      this.currentTime = Object.freeze(parseTime(nowTime, '{y}年{m}月{d}日 {h}:{i}'))
    }, 1000)

    // 必须使用 nextTick，因为要确保所有图表实例初始化完成后才能操作他们
    this.$nextTick(() => {
      // 开始初始化所有 echarts 实例
      this.renderChart = true
      // this.oemOpts = { total: 2, rows: [{ searchValue: null, createBy: null, createTime: '2021-05-15 15:42:10', updateBy: null, updateTime: null, remark: null, params: {}, id: 1, name: 'OEM', status: '1', platformDb: 'CCG' }, { searchValue: null, createBy: null, createTime: '2021-05-15 15:42:19', updateBy: null, updateTime: null, remark: null, params: {}, id: 2, name: 'OEM2', status: '1', platformDb: 'CCG' }], code: 200, msg: '查询成功', extraDate: null }.rows
      // this.platformOpts = { total: 2, rows: [{ searchValue: null, createBy: null, createTime: '2021-05-15 15:42:49', updateBy: null, updateTime: null, remark: null, params: {}, id: 1, name: '15N', status: '1', platformDb: 'CCG' }, { searchValue: null, createBy: null, createTime: '2021-05-15 15:42:59', updateBy: null, updateTime: null, remark: null, params: {}, id: 2, name: 'Z15N', status: '1', platformDb: 'CCG' }], code: 200, msg: '查询成功', extraDate: null }.rows
      // this.clientGroupOpts = { total: 2, rows: [{ searchValue: null, createBy: null, createTime: '2021-05-15 15:43:24', updateBy: null, updateTime: null, remark: null, params: {}, id: 1, name: 'Z15N终端车辆', status: '1', platformDb: 'CCG' }, { searchValue: null, createBy: null, createTime: '2021-05-15 15:43:39', updateBy: null, updateTime: null, remark: null, params: {}, id: 2, name: 'Z15N终端车辆11111111', status: '1', platformDb: 'CCG' }], code: 200, msg: '查询成功', extraDate: null }.rows
      getOemOps().then((opts: any) => {
        this.oemOpts = Object.freeze(opts.rows)
        // console.log('oem', opts)
      })

      getUserGroupOps().then((opts: any) => {
        this.clientGroupOpts = Object.freeze(opts.rows)
        // console.log('客户', opts)
      })

      getPlatformOps().then((opts: any) => {
        this.platformOpts = Object.freeze(opts.rows)
        // console.log('平台', opts)
      })

      // 初始化图表数据，通过模拟触发搜索条件改变事件实现
      this.topSelectChange(true)

      setInterval(() => {
        this.dutyCycleMachine.currState = 'init'
        this.getDutyCycleData({
          oem: this.oemSeleModel,
          platform: this.platformSeleModel,
          userGroup: this.clientGroupModel
        })
        // this.$message.success(`刷新 dutycycle, ${CHART_UPDATE_CYCLE_FE.dutyCycle}`)
      }, CHART_UPDATE_CYCLE_FE.dutyCycle)

      setInterval(() => {
        this.CCMaxStateMachine.currState = 'init'
        this.getCCMaxTempData({
          oem: this.oemSeleModel,
          platform: this.platformSeleModel,
          userGroup: this.clientGroupModel
        })
        // this.$message.success(`刷新 ccMax, ${CHART_UPDATE_CYCLE_FE.ccMax}`)
      }, CHART_UPDATE_CYCLE_FE.ccMax)

      setInterval(() => {
        this.turboEntryPressStateMachine.currState = 'init'
        this.getTurboData({
          oem: this.oemSeleModel,
          platform: this.platformSeleModel,
          userGroup: this.clientGroupModel
        })
        // this.$message.success(`刷新 turbo, ${CHART_UPDATE_CYCLE_FE.turbo}`)
      }, CHART_UPDATE_CYCLE_FE.turbo)

      setInterval(() => {
        this.crashCauseTimeStateMachine.currState = 'init'
        this.getCrashData({
          oem: this.oemSeleModel,
          platform: this.platformSeleModel,
          userGroup: this.clientGroupModel
        })
        // this.$message.success(`刷新 crash, ${CHART_UPDATE_CYCLE_FE.crash}`)
      }, CHART_UPDATE_CYCLE_FE.crash)

      setInterval(() => {
        this.mapChartMachine.currState = 'init'
        this.getMapData({
          oem: this.oemSeleModel,
          platform: this.platformSeleModel,
          userGroup: this.clientGroupModel
        })
        // this.$message.success(`刷新 map, ${CHART_UPDATE_CYCLE_FE.map}`)
      }, CHART_UPDATE_CYCLE_FE.map)

      setInterval(() => {
        this.getMapProfileData({
          oem: this.oemSeleModel,
          platform: this.platformSeleModel,
          userGroup: this.clientGroupModel
        })
        // this.$message.success(`刷新 mapProfile, ${CHART_UPDATE_CYCLE_FE.mapProfile}`)
      }, CHART_UPDATE_CYCLE_FE.mapProfile)

      setInterval(() => {
        this.customerConfuseStateMachine.currState = 'init'
        this.getCustomConfuseData({
          oem: this.oemSeleModel,
          platform: this.platformSeleModel,
          userGroup: this.clientGroupModel
        })
        // this.$message.success(`刷新 mapProfile, ${CHART_UPDATE_CYCLE_FE.mapProfile}`)
      }, CHART_UPDATE_CYCLE_FE.customConfuse)
    })
  }

  /**
   * 顶部搜索条件切换事件监听
   * @param { Boolean } firstInit 是否是第一次加载
   */

  topSelectChange(firstInit: boolean = false) {
    if (!firstInit) this.batchChangeState('init')

    const selectors = {
      oem: this.oemSeleModel,
      platform: this.platformSeleModel,
      userGroup: this.clientGroupModel
    }

    setTimeout(() => {
      this.getCCMaxTempData(selectors)
      this.getDutyCycleData(selectors)
      this.getTurboData(selectors)
      this.getCrashData(selectors)
      this.getMapData(selectors)
      this.getMapProfileData(selectors)
      this.getCustomConfuseData(selectors)
    }, 300)
  }

  // --------------------------- 退出登录/全屏 start ------------------------ //

  async logout() {
    this.logoutLoading = true
    try {
      await UserModule.LogOut()
      this.$router.replace({
        name: 'Login'
      })
    } catch {
      console.error('退出登录失败')
    }
    this.logoutLoading = false
  }

  batchChangeState(state) {
    this.dutyCycleMachine.currState = state
    this.CCMaxStateMachine.currState = state
    this.turboEntryPressStateMachine.currState = state
    this.mileStateMachine.currState = state
    this.crashCauseTimeStateMachine.currState = state
    this.customerConfuseStateMachine.currState = state
    this.mapChartMachine.currState = state
  }

  toggleFullScreen() {
    const elem = document.querySelector('.chart-wrapper')

    this.$fullscreen.toggle(elem as HTMLElement, {
      // @ts-ignore
      wrap: false,
      callback: this.fullscreenChange
    })
  }

  fullscreenChange(fullscreen) {
    this.isFullScreen = fullscreen
  }

  // --------------------------- 退出登录/全屏 end ------------------------ //
}
