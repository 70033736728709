import echarts, { ECharts } from 'echarts'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'AmapRenderMixin'
})
export default class extends Vue {
  chart!: ECharts
  BASE_CONFIG = {}

  @Prop({ default: '200px' }) readonly chartHeight!: string
  @Prop({ default: '100%' }) readonly chartWidth!: string

  // /**
  //  * 绘制图表
  //  */

  drawChart(options) {
    if (!this.chart) {
      this.chart = echarts.init(this.$el as HTMLDivElement)
    }
    this.chart.setOption(options)
  }
}
