import { Component, Vue } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { EChartOption } from 'echarts'

// tools
import StateMachine from '@/class/stateMachine'
import { refreshToken } from '@/utils/tokenExpire'

// apis
import { getTurboData } from '@/api/dashboard'

@Component({
  name: 'TurboEntryPressMixin'
})
export default class extends Vue {
  turboEntryPressStateMachine: StateMachine = new StateMachine();

  turboEntryPressLastUpdate: string = '--'; // turboEntryPress 最新更新时间
  turboEntryPressUpdateCycle: string = '--'; // turboEntryPress 更新周期
  turboEntryPressConfig: EChartOption = {}; // turboEntryPressConfig

  created() {
    // turbo进气压力最大值分布
    this.turboEntryPressConfig = {
      legend: {
        data: ['进气压力']
      },
      tooltip: {
        trigger: 'item',
        formatter: function(value) {
          // @ts-ignore
          return `${value.seriesName}: ${value.name} <br> 车辆数：${value.value}`
        }
      },
      xAxis: {
        type: 'category',
        data: []
      },
      series: [
        {
          name: '进气压力',
          type: 'bar',
          barGap: '0',
          itemStyle: {
            color: '#d37b5f'
          },
          data: []
        }
        // {
        //   name: '排气压力',
        //   type: 'bar',
        //   barGap: '0',
        //   itemStyle: {
        //     color: '#017db4'
        //   },
        //   data: []
        // }
      ]
    }
  }

  async getTurboData(selectors) {
    this.turboEntryPressStateMachine.changeState('reload')
    try {
      const { data } = await getTurboData({
        headers: {
          oem: encodeURIComponent(selectors.oem.join(',')),
          platform: encodeURIComponent(selectors.platform.join(',')),
          userGroup: encodeURIComponent(selectors.userGroup.join(','))
        }
      })
      // const data = { area: ['100', '200', '300', '400', '500', '600', '700', '800', '900', '1000'], ExhaustL: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'], lastTime: '2021-05-13 17:23:51', IntakeL: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'], pl: '1周1次' }
      this.turboEntryPressUpdateCycle = data.pl
      this.turboEntryPressLastUpdate = data.lastTime
      this.turboEntryPressStateMachine.changeState('resolve')

      this.$nextTick(() => {
        this.updateTurboEntryPressChart(data)
      })
    } catch (error) {
      if (error.message === '401:expired') {
        await refreshToken()
        location.reload()
      }

      if (error.message !== '402:abort') {
        console.error('获取 turbo 数据失败！')
        this.turboEntryPressStateMachine.changeState('reject')
      }
    }
  }

  updateTurboEntryPressChart(data) {
    const interval = (data.area.slice(-1)[0] - data.area[0]) / (data.area.length - 1)

    //  @ts-ignore
    this.turboEntryPressConfig.xAxis.data = Object.freeze(data.area.map(item => {
      return `${(+item) - (interval)}~${item} Kpa`
    }))
    // //  @ts-ignore
    // this.turboEntryPressConfig.xAxis.min = data.area[0]
    //  @ts-ignore
    // this.turboEntryPressConfig.xAxis.max = data.area.slice(-1)[0]
    //  @ts-ignore
    this.turboEntryPressConfig.series[0].data = Object.freeze(data.IntakeL)
    //  @ts-ignore
    // this.turboEntryPressConfig.series[1].data = Object.freeze(data.ExhaustL)
  }
}
