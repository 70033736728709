









// decorator
import { Component, Prop, Vue } from 'vue-property-decorator'

// tools
import { debounce } from 'lodash'

@Component({
  name: 'ResponseContainer'
})
export default class extends Vue {
  @Prop({ default: 1920 }) readonly width!: number
  @Prop({ default: 1080 }) readonly height!: number

  scaleLevel:number = 1

  get containerInlineStyle() {
    return {
      transform: `scale(${this.scaleLevel}) translate(-50%, -50%)`,
      width: `${this.width}px`,
      height: `${this.height}px`
    }
  }

  getScale() {
    const width = this.width
    const height = this.height

    const ww = window.innerWidth / width
    const wh = window.innerHeight / height
    return ww < wh ? ww : wh
  }

  get debSetScale() {
    return debounce(() => {
      this.scaleLevel = this.getScale()
    }, 500)
  }

  mounted() {
    this.debSetScale()
    window.addEventListener('resize', this.debSetScale)
  }

  destoryed() {
    window.removeEventListener('resize', this.debSetScale)
  }
}
