








// decorator
import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'

// mixins
import ResizeMixin from '../mixins/resize'
import ChartRenderMixin from '../ccg/mixins/ChartRenderMixin'

@Component({
  name: 'BubbleChart'
})
export default class extends mixins(ResizeMixin, ChartRenderMixin) {
  created() {
    this.BASE_CONFIG = {
      tooltip: {
        trigger: 'item'
      },
      grid: {
        top: '8%',
        left: '5%',
        right: '8%',
        bottom: '5%',
        containLabel: true
      },
      xAxis: {
        axisLine: {
          lineStyle: {
            color: '#999'
          }
        },
        splitLine: {
          show: false
        }
      },
      yAxis: {
        axisLine: {
          show: true,
          lineStyle: {
            color: '#999'
          }
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: '#999'
          }
        },
        scale: true
      }
    }
  }
}
