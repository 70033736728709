import { Component, Vue } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { EChartOption } from 'echarts'

// tools
import StateMachine from '@/class/stateMachine'
import { refreshToken } from '@/utils/tokenExpire'

// apis
import { getCCMaxTempData } from '@/api/dashboard'

@Component({
  name: 'CCMaxTempMixin'
})
export default class extends Vue {
  CCMaxTempLastUpdate: string = '--'; // CC温度最大值分布最新更新时间
  CCMaxTempUpdateCycle: string = '--'; // CC温度最大值分布更新周期
  CCMaxTempConfig: EChartOption = {}; // CC温度最大值分布
  CCMaxStateMachine: StateMachine = new StateMachine();

  created() {
    // cc温度最大值分布 option
    this.CCMaxTempConfig = {
      tooltip: {
        formatter: function(params) {
          // @ts-ignore
          return `温度：${params.name} <br> 车辆数：${params.value} <br>`
        }
      },
      xAxis: {
        type: 'category',
        data: []
      },
      series: [
        {
          type: 'bar',
          barGap: '0',
          data: [],
          barMaxWidth: 25
        }
      ]
    }
  }

  async getCCMaxTempData(selectors) {
    this.CCMaxStateMachine.changeState('reload')
    try {
      const { data } = await getCCMaxTempData({
        headers: {
          oem: encodeURIComponent(selectors.oem.join(',')),
          platform: encodeURIComponent(selectors.platform.join(',')),
          userGroup: encodeURIComponent(selectors.userGroup.join(','))
        }
      })
      // const data = { lastTime: '2021-05-13 17:23:51', area: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'], data: ['10', '20', '25', '35', '42', '80', '30', '46', '13', '65'], pl: '1周1次', bfb: ['0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%', '0%'] }
      this.CCMaxTempLastUpdate = data.lastTime
      this.CCMaxTempUpdateCycle = data.pl
      this.CCMaxStateMachine.changeState('resolve')
      this.updateCCMaxTempChart(data)
    } catch (error) {
      if (error.message === '401:expired') {
        await refreshToken()
        location.reload()
      }

      if (error.message !== '402:abort') {
        console.error('获取 ccMaxTemp 数据失败！')
        this.CCMaxStateMachine.changeState('reject')
      }
    }
  }

  updateCCMaxTempChart(data) {
    // const interval = (data.area.slice(-1)[0] - data.area[0]) / (data.area.length - 1)
    const interval = 100 // ccg 第一次需求变更后，将原来（上方代码）的自动计算横轴区间间隔改为固定值 100

    //  @ts-ignore
    this.CCMaxTempConfig.xAxis.data = Object.freeze(data.area.map(item => {
      return `${(+item) - (interval)}-${item} ℃`
    }))

    //  @ts-ignore
    // this.CCMaxTempConfig.xAxis.data = data.area
    //  @ts-ignore
    // this.CCMaxTempConfig.xAxis.min = Object.freeze(data.area[0])
    // //  @ts-ignore
    // this.CCMaxTempConfig.xAxis.max = Object.freeze(data.area.slice(-1)[0])
    //  @ts-ignore
    this.CCMaxTempConfig.series[0].data = Object.freeze(data.data.map((item, ind) => {
      return ind > data.flag ? {
        value: item,
        itemStyle: {
          color: '#d9001b'
        }
      } : {
        value: item,
        itemStyle: {
          color: '#017db4'
        }
      }
    }))
  }
}
