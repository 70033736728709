import axios from 'axios'
import { Message } from 'element-ui'
import { UserModule } from '@/store/modules/user'
import { checkExpire, refreshToken } from '@/utils/tokenExpire'
import { addPendingRequest, removePendingRequest } from '@/utils/cancelRequest'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 300000
})

// Request interceptors
service.interceptors.request.use(
  async(config) => {
    if (checkExpire()) {
      try {
        await refreshToken() // 检查 token 是否过期，若过期了需要重新获取后再请求
      } catch {
        // Message.error('登录过期！')
        throw new Error('尝试请求接口刷新 token 失败！')
      }
    }
    // console.log('真正的接口请求')
    if (UserModule.token) {
      removePendingRequest(config) // 检查是否存在重复请求，若存在则取消已发的请求
      addPendingRequest(config) // 把当前请求信息添加到pendingRequest对象中
      // 为所有请求头添加 token
      config.headers.Authorization = `Bearer ${UserModule.token}`
      config.headers.plantDB = 'CCG'
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  (response) => {
    removePendingRequest(response.config) // 从pendingRequest对象中移除请求

    const res = response.data
    if (res.code !== 200) {
      if (res.code === 401) {
        return Promise.reject(new Error('401:expired'))
      }

      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 3 * 1000
      })
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return response.data
    }
  },
  (error) => {
    removePendingRequest(error.config || {}) // 从pendingRequest对象中移除请求
    if (axios.isCancel(error)) {
      console.log('已取消的重复请求：' + error.message)
      return Promise.reject(new Error('402:abort'))
    }

    let message = ''
    if (error.code === 'ECONNABORTED') {
      message = '请求超时，请重试'
    } else if (!error.code) {
      message = '无法请求到服务器！'
    } else {
      message = error.message
    }

    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
